<template>
  <div>
    <van-cell title="温度检测">
      <template #right-icon>
        <van-checkbox v-model="llz001_Step1.wenDu" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="触摸屏测试">
      <template #right-icon>
        <van-checkbox v-model="llz001_Step1.chuMoPing" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="电位标定检测">
      <template #right-icon>
        <van-checkbox v-model="llz001_Step1.dianWeiBiaoDing" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="电机位移检测">
      <template #right-icon>
        <van-checkbox v-model="llz001_Step1.dianJiWeiYi" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="左右限位检测">
      <template #right-icon>
        <van-checkbox v-model="llz001_Step1.zuoYouXianWei" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="无线模块检测">
      <template #right-icon>
        <van-checkbox v-model="llz001_Step1.wuXianMoKuai" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="升级版本检测">
      <template #right-icon>
        <van-checkbox v-model="llz001_Step1.shengJiBanBen" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-field label="备注" v-model="llz001_Step1.remark"> </van-field>
    <div class="footer">
      <van-button type="info" v-show="$parent.hasPower_Step1" :disabled="$parent.llz001_SignNumber.status!=0" round block @click="save_LLZ001_Step1">保存</van-button>
      <van-button type="danger" v-if="$parent.llz001_SignNumber.status==2" round block @click="$parent.cancel_void_SignNumber()">取消作废</van-button>
      <van-button type="danger" v-else-if="$parent.llz001_SignNumber.status!=1" round block @click="$parent.void_SignNumber()">作废</van-button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["signNumber"],
  data() {
    return {
      llz001_Step1: {},
    };
  },
  methods: {
    getLLZ001_Step1() {
      let that = this;
      that.axios.post("/LLZ001/GetLLZ001_Step1", { onlyText: that.signNumber }).then(function (response) {
        that.llz001_Step1 = response.data.data;
      });
    },
    save_LLZ001_Step1() {
      let that = this;
      that.axios.post("LLZ001/UpdateLLZ001_Step1", that.llz001_Step1).then(function (response) {
        that.llz001_Step1 = response.data.data;
        that.$notify({ type: "success", message: response.data.msg });
      });
    },
  },
  mounted() {
    let that = this;
    that.getLLZ001_Step1();
  },
};
</script>

<style scoped>
.footer {
  position: fixed;
  left: 0px;
  bottom: 0px;
  width: 100%;
}
</style>