<template>
  <div style="padding-bottom:44px">
    <van-field label="序列号" v-model="llz001_Step2.serialNumber" placeholder="请输入序列号">
      <template #right-icon>
        <van-tag type="warning">{{jieMa}}</van-tag>
      </template>
    </van-field>
    <van-cell title="温度检测">
      <template #right-icon>
        <van-checkbox v-model="llz001_Step2.wenDu" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="触摸屏测试">
      <template #right-icon>
        <van-checkbox v-model="llz001_Step2.chuMoPing" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="电位标定检测">
      <template #right-icon>
        <van-checkbox v-model="llz001_Step2.dianWeiBiaoDing" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="电机位移检测">
      <template #right-icon>
        <van-checkbox v-model="llz001_Step2.dianJiWeiYi" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="左右限位检测">
      <template #right-icon>
        <van-checkbox v-model="llz001_Step2.zuoYouXianWei" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="无线模块检测">
      <template #right-icon>
        <van-checkbox v-model="llz001_Step2.wuXianMoKuai" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="升级版本检测">
      <template #right-icon>
        <van-checkbox v-model="llz001_Step2.shengJiBanBen" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="注射泵容量检测">
      <template #right-icon>
        <van-checkbox v-model="llz001_Step2.zhuSheBangRongLiang" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="搅拌电机检测">
      <template #right-icon>
        <van-checkbox v-model="llz001_Step2.jiaoBanDianJi" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="电极检测">
      <template #right-icon>
        <van-checkbox v-model="llz001_Step2.dianJi" shape="square"></van-checkbox>
      </template>
    </van-cell>

    <van-field label="备注" v-model="llz001_Step2.remark"> </van-field>
    <el-button type="primary" class="footer1" icon="el-icon-view" circle @click="handlePicClick"></el-button>
    <div class="footer">
      <van-button type="info" v-show="$parent.hasPower_Step2" round block :disabled="$parent.llz001_SignNumber.status!=0" @click="handleSave">保存</van-button>
      <van-button type="danger" v-if="$parent.llz001_SignNumber.status==2" round block @click="$parent.cancel_void_SignNumber()">取消作废</van-button>
      <van-button type="danger" v-else-if="$parent.llz001_SignNumber.status!=1" round block @click="$parent.void_SignNumber()">作废</van-button>

    </div>
  </div>
</template>

<script>
import { ImagePreview } from "vant";
export default {
  props: ["signNumber"],
  data() {
    return {
      llz001_Step2: {},
      jieMa: "",
    };
  },
  methods: {
    handlePicClick() {
      ImagePreview(["./images/LLZ001_Produce.png"]);
    },
    getLLZ001_Step2() {
      let that = this;
      that.axios.post("/LLZ001/GetLLZ001_Step2", { onlyText: that.signNumber }).then(function (response) {
        that.llz001_Step2 = response.data.data;
      });
    },
    handleSave() {
      let that = this;
      that.axios.all([that.save_LLZ001_Step2(), that.registerSerialNumber()]).then(
        that.axios.spread(function (response1, response2) {
          that.llz001_Step2 = response1.data.data;
          if (response1.data.code == 201) {
            that.$notify({ type: "warning", message: response1.data.msg });
          } else if (response2.data.code == 201) {
            that.$notify({ type: "warning", message: response2.data.msg });
          } else {
            that.$notify({ type: "success", message: response1.data.msg });
          }
          if (response2.data.code == 101) {
            that.jieMa = response2.data.msg;
          }
        })
      );
    },
    save_LLZ001_Step2() {
      let that = this;
      return that.axios.post("LLZ001/UpdateLLZ001_Step2", that.llz001_Step2);
    },
    registerSerialNumber() {
      let that = this;
      return that.axios.post("Base_Device/Register", {
        productName: "全自动氯离子",
        productId: 1068,
        serialNumber: that.llz001_Step2.serialNumber,
        deviceType: "LLZ001",
        companyName: "",
        ifPrint: false,
      });
    },
  },
  mounted() {
    let that = this;
    that.getLLZ001_Step2();
  },
};
</script>

<style scoped>
.footer {
  position: fixed;
  left: 0px;
  bottom: 0px;
  width: 100%;
}
.footer1 {
  position: fixed;
  left: 10px;
  bottom: 50px;
}
</style>